<template>
  <div class="flex-1 flex flex-col  max-h-full">
    <sub-header :handleClick="handleClick" :backButton="true" backRedirectionUrl="/check-admin"  :showBtn="checkViewData && checkViewData.is_custom" buttonIcon="edit" buttonText="Edit Check" />
    <div class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar mx-6">
        <dv-table :isLoading="isLoading" :headers="columns" :isCollapsible="true" collapseKey="checkSections" :rows="items">
            <template v-slot:items="{ row }">
                <td class="flex-wrap py-4 px-3 text-sm">
                    <div class="flex items-center gap-2">
                      <div >
                        <div class="font-medium text-gray-900 max-w-xs line-clamp-1">{{row.name}}</div>
                        <p class="text-gray-500 max-w-xs break-words truncate text-xs line-clamp-1"> {{row.description}} </p>
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{row.label}}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span class="inline-flex rounded-sm  px-2 text-xs font-semibold leading-5 " :class="row.is_custom ? 'bg-green-100 text-green-800' : 'bg-gray-200 text-gray-800' " >{{row.is_custom? 'Custom': 'locked'}}</span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <font-awesome-icon icon='input-text' class="icon alt" />  {{row.type}}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <p class="text-gray-500 max-w-xs break-words truncate text-xs line-clamp-1"> {{row.placeholder}} </p>
                  </td>

                  
                  <!-- <td class="py-4 px-2 text-center text-xl font-medium">
                        <font-awesome-icon icon="angle-right" class=" text-brand w-32 text-2xl self-center cursor-pointer hover:opacity-70" />
                  </td> -->
            </template>
            <template v-slot:no-data>
              <span class="w-full flex items-center justify-center p-3">No data Found</span>
          </template>
        </dv-table>
    </div>
  </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import dvTable from  "@/components/devliumComponent/field-table/dv-table"
// import { columns, rows } from '../field-table/fieldTableData'
import { fields } from '../field-table/fieldData'
import axios from "@/axios";

export default {
  name: "check-admin",
  components: {
      SubHeader,
      dvTable,
  },
  props: {},
  data: () => ({
        columns: [
          {"label":"Name","field":"name"},
          {"label":"Label","field":"label"},
          {"label":"Source","field":"is_custom"},
          {"label":"Type","field":"type"},
          {"label":"Placeholder","field":"placeholder"}

          
          ],
        items: [],
        fieldData: fields,
        isLoading:false,
        checkViewData: null 
  }),
  async mounted() {
    if (!this.$store.getters.getTenantId) {
			await this.$store.dispatch('fetchTenantId')
		}
    await this.getCheckDetails()
  },
  methods: {
        getIcon(field_name){
            return this.fieldData.find(ele => ele.type === field_name).svg || ""
        },
        handleClick(){
            this.$router.push({path:`/check-admin/view/${this.$route.params.id}/setting`});
        },
        
        // Get Check Details

        async getCheckDetails() {
          this.isLoading = true
          let queryId = this.$route.params.id
          if(queryId){
            let url = `/tenant-check/${this.$store.getters.getTenantId}/${queryId}`;
            let {data} = await axios.get(url);
            try{
                let checkData = data.data
                this.checkViewData = data.data
                let fieldsList = []
                checkData.sections?.map(section => section.fields?.map(field => fieldsList.push(field)))
                this.items = fieldsList
                this.isLoading = false
            }catch(error){
              this.isLoading = false
            }
            this.isLoading = false
          
            // let checkData = rows.find(ele => ele.id == queryId)
            // let checkSectionsfields = []
            // checkData.checkSections.map(el => el.checkFields.map(element => checkSectionsfields.push(element)))
            // return this.items  = checkSectionsfields
        }

        // End Get Check Details
    },
  },
};
</script>